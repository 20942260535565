<script setup>

import {onMounted, ref} from "vue";
import {Bootstrap5Pagination} from 'laravel-vue-pagination';
import moment from "moment/moment";
import SendOTPForPayment from "../../../wallet/payments/sendOTPForPayment.vue";
import ResendOTP from "../../../ResendOTP.vue";
import Swal from "sweetalert2";

$(document).ready(function() {
    $('[data-toggle="tooltip"]').tooltip();
});

const currency = import.meta.env.VITE_LOCALIZATION_COUNTRY_CURRENCY_SIGN;
const posFee = import.meta.env.VITE_POS_APPLICATION_FEE;
const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger"
    },
    buttonsStyling: false
});
const props = defineProps([
    'totalMarkets',
    'totalMarketsAmount',
    'totalMembers',
    'totalMembersAmount',
    'totalTransactions',
    'totalTransactionsAmount',
    'currentLimit',
    'posApplication',
    'account',
    'user',
]);

const search = ref('');
const selectedTransaction = ref({});
const transactions = ref({});
const errors = ref({});
const childRef = ref();
const confirming = ref(false);
const saving = ref(false);
const sentOtpToUser = ref(false);
const otp = ref('');
const market_id = ref(props.user.groups[0].id);

const orderNumber = ref('');
const fee = ref('');
const walletType = ref('');
const posId = ref('');
const posPositiveStatuses = ['pending', 'approved', 'requested', 'assigned', 'delivered', 'active'];

const setChildRef = (el) => {
    childRef.value = el;
};

function populateWalletTransaction(transaction) {
    if (transaction) {
        selectedTransaction.value = transaction;

        let type = transaction.payload?.type ?? null;

        if (type === 'order') {
            orderNumber.value = transaction.payload?.order?.ref;
            fee.value = '';
            walletType.value = '';
            posId.value = '';
        }else if (type === 'fee') {
            fee.value = `[${transaction.payload?.fee?.type}] ${transaction.payload?.fee?.name}`;
            orderNumber.value = '';
            walletType.value = '';
            posId.value = '';
        }else if (type === 'wallet_transfer') {
            walletType.value = 'Wallet Transfer';
            orderNumber.value = '';
            fee.value = '';
            posId.value = '';
        }else if (type === 'pos_application') {
            posId.value = transaction.payload?.pos_application?.id;
            orderNumber.value = '';
            fee.value = '';
            walletType.value = '';
        }else{
            orderNumber.value = '';
            fee.value = '';
            walletType.value = '';
            posId.value = '';
        }
    }
}

const getWalletTransactions = async (page = 1) => {
    await axios.get(`/apis/v4/saas/wallet-transactions?user_id=${props.user.id}&page=${page}&keyword=${search.value}`).then( resp => {
        transactions.value = resp.data.data;
    }).catch(err => {
        errors.value = err.response.data.errors;
    });
}

function formatDate(value) {
    return moment(value).format('DD/MM/YYYY HH:mm:ss');
}

function currencyFormat(amount) {
    let amounts = new Intl.NumberFormat("en-KE", {
        maximumSignificantDigits: 8,
    }).format(amount);
    return `${currency}${amounts}`;
}

function sendOtp() {
    sentOtpToUser.value = true;
    let payAmount = currencyFormat(posFee);

    axios.post('/apis/v4/otp/send', {
        phone: props.user.phone,
        user_id: props.user.id,
        key: 'pos_payment',
        message: `You are about to pay ${payAmount} from your wallet. ${payAmount} will be removed from your wallet. To accept, enter this code {otp}`
    }).then(function () {
        sentOtpToUser.value = true;
        childRef.value.startTimer();
    }).catch(function () {
        sentOtpToUser.value = false;
    });
}

function makePayment() {
    saving.value = true;

    axios.post('/apis/v4/otp/validate', {
        otp: otp.value,
        user_id: props.user.id,
        key: 'pos_payment',
    }).then(() => {
        completePayment()
    }).catch((error) => {
        saving.value = false;
        errors.value = error?.response?.data?.errors ?? '';
    });
}

function completePayment() {
    saving.value = true;

    axios.post('/apis/v4/saas/pos-applications', {
        'market_id': market_id.value,
        'amount': posFee,
    }).then(() => {
        bootstrap?.Modal?.getOrCreateInstance(document.querySelector("#applyPosModal"))?.hide();
        bootstrap?.Modal?.getOrCreateInstance(document.querySelector("#otpModal"))?.hide();

        swalWithBootstrapButtons.fire({
            title: '',
            icon: '',
            html: `
    <div class="col-12" style="padding: 10px;text-align: left">
        <h4 class="text-success" style="font-weight: 600;">Payment Complete</h4>
        <div class="col-12 mt-3" style="font-weight: 500; font-size: 16px">
            <p>Your application for a POS device is in <strong>review</strong>. You will get an SMS once your POS is deployed.</p>
        </div>
    </div>
  `,
            showCloseButton: true,
            focusConfirm: true,
            confirmButtonText: `Okay`,
        }).then(() => {
            location.reload();
        });
    }).catch((error) => {
        saving.value = false;
        let defaultErrorMsg = 'Error completing request. Please try again and contact our support team if problem persists.';
        let errorMsg = error?.response?.data?.trace?.error[0] ?? defaultErrorMsg;

        Swal.fire({
            position: 'center',
            icon: 'error',
            title: `<h5>${errorMsg}</h5>`,
        }).then(() => {
            location.reload();
        });
    });
}

function confirmDelivery() {
    confirming.value = true;

    swalWithBootstrapButtons.fire({
        title: '',
        icon: '',
        html: `
    <div class="col-12" style="padding: 10px;text-align: left">
        <h4 class="text-success" style="font-weight: 600;">Confirm delivery of POS device</h4>
        <div class="col-12 mt-3" style="font-size: 16px">
            <p>By clicking confirm, you are accepting that a POS device has been given to you to transact for members on Amana Market.</p>
        </div>
    </div>
  `,
        showCloseButton: true,
        focusConfirm: true,
        confirmButtonText: `Confirm`,
    }).then((result) => {
        if (result.isConfirmed) {
            axios
                .put('/apis/v4/saas/pos-applications/' + props.posApplication.id, {
                    status: 'active',
                }).then(() => {
                    location.reload();
                }).catch((error) => {
                    confirming.value = false;
                    let errorMsg = error?.response?.data?.trace?.error[0] ?? 'Error confirming POS delivery.';

                    Swal.fire({
                        icon: 'error',
                        title: `<h5>${errorMsg}</h5>`,
                    });
                });
        }else{
            confirming.value = false;
        }
    });
}


function setSearchWithdrawalRequest() {
    let urlParams = new URLSearchParams(window.location.search);
    search.value = urlParams.has('search') ? urlParams.get('search') : '';
}

onMounted(function () {
    setSearchWithdrawalRequest();
    getWalletTransactions();
});

</script>

<template>
    <div>
        <div class="row row-cols-1 row-cols-md-2 row-cols-xl-4">
            <div class="col">
                <div class="card radius-10">
                    <div class="card-body">
                        <div class="d-flex align-items-center">
                            <div class="col-12">
                                <div class="col-12">
                                    <div class="mb-0">
                                        {{ 'Verified markets referred' }}
                                        <div class="tooltip-1">
                                            <i class="fa fa-info-circle text-success"/>
                                            <div class="tooltip-text">
                                                <p>These are markets that were added to Amana market using your referral code that have passed verification.</p>
                                                <p><strong>You earn N10,000 for each market that uses your referral code and passes verification.</strong></p>
                                            </div>
                                        </div>
                                    </div>
                                    <i class="fa-solid fa-shop fa-2x text-secondary" style="float: right"/>
                                    <h6 class="my-1" style="font-weight: 700">{{ totalMarkets }}</h6>
                                </div>
                                <hr class="text-success" style="border: solid 2px"/>
                                <div class="col-12 text-success">
                                    <p class="mb-0">{{ 'Total Amount Added to wallet' }}</p>
                                    <h6 class="my-1 text-success">
                                        {{ currency }}{{ totalMarketsAmount.toLocaleString() }}
                                    </h6>
                                    <p/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card radius-10">
                    <div class="card-body">
                        <div class="d-flex align-items-center">
                            <div class="col-12">
                                <div class="col-12">
                                    <div class="mb-0">
                                        {{ 'Verified members added' }}
                                        <div class="tooltip-1">
                                            <i class="fa fa-info-circle text-success"/>
                                            <div class="tooltip-text">
                                                <p>These are members that you have added to Amana market that have passed verification.</p>
                                                <p><strong>You earn N1,000 for each member that you added and have passed verification.</strong></p>
                                            </div>
                                        </div>
                                    </div>
                                    <i class="fa-solid fa-user fa-2x text-secondary" style="float: right"/>
                                    <h6 class="my-1" style="font-weight: 700">{{ totalMembers }}</h6>
                                </div>
                                <hr class="text-success" style="border: solid 2px"/>
                                <div class="col-12 text-success">
                                    <p class="mb-0">{{ 'Total Amount Added to wallet' }}</p>
                                    <h6 class="my-1 text-success">
                                        {{ currency }}{{ totalMembersAmount.toLocaleString() }}
                                    </h6>
                                    <p/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card radius-10">
                    <div class="card-body">
                        <div class="d-flex align-items-center">
                            <div class="col-12">
                                <div class="col-12">
                                    <div class="mb-0">
                                        {{ 'Transactions completed' }}
                                        <div class="tooltip-1">
                                            <i class="fa fa-info-circle text-success"/>
                                            <div class="tooltip-text">
                                                <p>These are number of transactions which you have performed on Amana market.</p>
                                                <p><strong>You earn agent fees for each of the transactions you have performed.</strong></p>
                                            </div>
                                        </div>
                                    </div>
                                    <i class="fa-solid fa-money-bill fa-2x text-secondary" style="float: right"/>
                                    <h6 class="my-1" style="font-weight: 700">{{ totalTransactions }}</h6>
                                </div>
                                <hr class="text-success" style="border: solid 2px"/>
                                <div class="col-12 text-success">
                                    <p class="mb-0">{{ 'Total Amount Added to wallet' }}</p>
                                    <h6 class="my-1 text-success">
                                        {{ currency }}{{ totalTransactionsAmount.toLocaleString() }}
                                    </h6>
                                    <p/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card radius-10">
                    <div class="card-body">
                        <div class="d-flex align-items-center">
                            <div class="col-12">
                                <div class="col-12">
                                    <div class="mb-0">
                                        {{ 'Current Agent Limit' }}
                                    </div>
                                    <i class="fa-solid fa-money-simple-from-bracket fa-2x text-secondary" style="float: right"/>
                                    <h6 class="my-1" style="font-weight: 700">
                                        {{ currency }}{{ currentLimit.toLocaleString() }}
                                    </h6>
                                </div>
                                <hr class="text-success" style="border: solid 2px"/>
                                <div class="col-12 text-success" v-if="posPositiveStatuses.includes(posApplication?.status)">
                                    <p class="mb-0" v-if="posApplication?.status === 'active'">POS: {{ posApplication?.terminal_id ?? '---' }}</p>
                                    <p class="mb-0" v-else>POS: ---</p>
                                    <h6 class="my-1">
                                        <button
                                            class="btn btn-success btn-sm"
                                            data-bs-toggle="offcanvas" href="#userPosModal"
                                            aria-controls="userPosModal"
                                        >
                                            My POS
                                        </button>
                                    </h6>
                                </div>
                                <div v-else class="col-12 text-success">
                                    <p class="mb-0">{{ 'Apply for a POS device' }}</p>
                                    <h6 class="my-1">
                                        <button
                                            class="btn btn-success btn-sm"
                                            :disabled="!account?.account_number"
                                            data-bs-toggle="modal"
                                            data-bs-target="#applyPosModal"
                                        >
                                            Get a POS
                                        </button>
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="card">
                <div class="card-body">
                    <div class="col-12">
                        <form action="/four/earnings" method="GET">
                            <div class="col-md-6">
                                <div class="input-group mb-3">
                                    <input type="text" class="form-control" placeholder="Search by activity, debit/credit or amount"
                                           name="search"
                                           id="searchTransactions"
                                           :value="search"
                                           aria-label="Search Transactions" aria-describedby="button-addon2">
                                    <button class="btn btn-outline-secondary" type="submit" id="performSearch">
                                        Search
                                        <i class="fa fa-search"></i>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="table-responsive">
                        <table class="table">
                            <thead v-if="transactions && transactions.data && transactions.data.length > 0">
                            <tr>
                                <th>#</th>
                                <th>Member</th>
                                <th>Market</th>
                                <th>Activity</th>
                                <th>Amount</th>
                                <th>Status</th>
                                <th>Date</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                            <tbody v-if="transactions && transactions.data && transactions.data.length > 0">
                            <tr v-for="(transaction,index) in transactions.data" :key="transaction.id">
                                <td scope="row">{{ index + 1 }}</td>
                                <td>{{ transaction.from_user_name }}</td>
                                <td>{{ transaction.from_user_market_name }}</td>
                                <td>{{ transaction.type }}</td>
                                <td><span class="badge bg-dark">{{ currencyFormat(transaction.amount) }}</span></td>
                                <td v-if="transaction.payment_type === 'credit'">
                                    <span class="badge bg-success">Paid to wallet</span>
                                </td>
                                <td v-else>
                                    <span class="badge bg-danger">Paid from wallet</span>
                                </td>
                                <td>{{ formatDate(transaction.created_at) }}</td>
                                <td>
                                    <button class="btn btn-sm btn-default"
                                            @click="populateWalletTransaction(transaction)"
                                            data-bs-toggle="offcanvas" href="#viewTransaction" role="button"
                                            aria-controls="viewTransaction"
                                    >
                                        See More <i class="fa fa-angle-double-right fa-thin"></i>
                                    </button>
                                </td>
                            </tr>
                            </tbody>
                            <tbody v-else>
                            <tr>
                                <td align="center" colspan="7">No record found.</td>
                            </tr>
                            </tbody>
                        </table>
                        <div class="col-12">
                            <bootstrap5-pagination :data="transactions" @pagination-change-page="getWalletTransactions" style="overflow: scroll"/>
                        </div>
                    </div>
                    <div class="offcanvas offcanvas-end" tabindex="-1" id="viewTransaction" aria-labelledby="viewTransactionLabel">
                        <div class="offcanvas-header">
                            <h5 class="offcanvas-title" id="viewTransactionLabel">Transaction Details</h5>
                            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div>
                        <div class="offcanvas-body">
                            <div class="table-responsive">
                                <table class="table table-borderless">
                                    <tbody>
                                    <tr>
                                        <td>Member:</td>
                                        <td><b>{{ selectedTransaction.from_user_name }}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Market:</td>
                                        <td><b>{{ selectedTransaction.from_user_market_name }}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Activity:</td>
                                        <td><b>{{ selectedTransaction.type }}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Type:</td>
                                        <td v-if="selectedTransaction.payment_type === 'credit'" class="text-success">
                                            <span class="badge bg-success">Credit</span>
                                        </td>
                                        <td v-else><span class="badge bg-danger">Debit</span></td>
                                    </tr>
                                    <tr>
                                        <td>Amount Paid:</td>
                                        <td>
                                            <span class="badge bg-dark">
                                                {{ currencyFormat(selectedTransaction.amount) }}
                                            </span>
                                        </td>
                                    </tr>
                                    <template v-if="orderNumber">
                                        <tr>
                                            <td>Transaction Type:</td>
                                            <td><b>Order</b></td>
                                        </tr>
                                        <tr>
                                            <td>Order:</td>
                                            <td><b>{{ orderNumber }}</b></td>
                                        </tr>
                                    </template>
                                    <template v-else-if="fee">
                                        <tr>
                                            <td>Transaction Type:</td>
                                            <td><b>Fee</b></td>
                                        </tr>
                                        <tr>
                                            <td>Fee:</td>
                                            <td><b>{{ fee }}</b></td>
                                        </tr>
                                    </template>
                                    <template v-else-if="posId">
                                        <tr>
                                            <td>Transaction Type:</td>
                                            <td><b>POS Fee</b></td>
                                        </tr>
                                        <tr>
                                            <td>POS Application #:</td>
                                            <td><b>{{ posId }}</b></td>
                                        </tr>
                                    </template>
                                    <template v-else-if="walletType">
                                        <tr>
                                            <td>Transaction Type:</td>
                                            <td><b>Wallet Transfer</b></td>
                                        </tr>
                                    </template>
                                    <template v-else>
                                        <tr>
                                            <td>Transaction Type:</td>
                                            <td><b>---</b></td>
                                        </tr>
                                    </template>
                                    <tr>
                                        <td>Status:</td>
                                        <td v-if="selectedTransaction.payment_type === 'credit'" class="text-success">
                                            <span class="badge bg-success">Paid to wallet</span>
                                        </td>
                                        <td v-else>
                                            <span class="badge bg-danger">Paid from wallet</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Transaction Reference:</td>
                                        <td><b>{{ selectedTransaction.tx_ref }}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Transaction Date:</td>
                                        <td><b>{{ formatDate(selectedTransaction.created_at) }}</b></td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="offcanvas offcanvas-end" tabindex="-1" id="userPosModal" aria-labelledby="userPosModalLabel">
        <div class="offcanvas-header">
            <h5 class="offcanvas-title" id="viewTransactionLabel">&nbsp;</h5>
            <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
            <div class="col-12" style="text-align: center">
                <img src="/images/power_vbank.png" alt="VFD" width="250"/>
            </div>
            <div class="col-12 mt-1">
                <div v-if="posApplication?.status === 'active'" class="table-responsive">
                    <table class="table table-borderless">
                        <tbody>
                        <tr>
                            <td>POS ID Number:</td>
                            <td style="font-weight: 600">{{ posApplication?.terminal_id ?? '---' }}</td>
                        </tr>
                        <tr>
                            <td>Linked Bank:</td>
                            <td style="font-weight: 600">{{ 'VFD MFB' }}</td>
                        </tr>
                        <tr>
                            <td>Account Number:</td>
                            <td style="font-weight: 600">{{ account?.account_number ?? '---' }}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div class="alert alert-warning mt-3" role="alert" v-else>
                    <p><strong>
                        Your POS device request has been made. <br/>
                        The current status is:
                        <span v-if="posApplication?.status === 'pending'" class="text-success">pending approval</span>
                        <span v-else class="text-success">{{ posApplication?.status }}</span>
                    </strong></p>
                </div>
            </div>
            <div class="col-12 mt-2" style="text-align: center" v-if="posApplication?.status === 'active'">
                <button class="btn btn-outline-success" data-bs-dismiss="offcanvas">
                    Manage my POS
                </button>
            </div>
            <div class="col-12 mt-2" style="text-align: center" v-else-if="posApplication?.status === 'delivered'">
                <button class="btn btn-outline-warning" @click="confirmDelivery" :disabled="confirming">
                    Confirm Delivery
                    <i class="fa fa-spin fa-spinner" v-if="confirming"></i>
                </button>
            </div>
        </div>
    </div>
    <div
        class="modal fade"
        id="applyPosModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="applyPosModal"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-body">
                    <button style="float: right" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    <div class="col-12" style="padding: 10px">
                        <h4 class="text-success" style="font-weight: 600">You are applying for a POS device</h4>
                        <div class="col-12 mt-3">
                            <p style="font-weight: 600">
                                Confirm the market you are applying in. Please note that POS devices require a payment
                                of <span style="color: darkorange">{{ currencyFormat(posFee) }}</span> from your wallet.
                            </p>
                            <div class="form-group">
                                <select name="market_id" v-model="market_id" class="form-control">
                                    <option v-for="m in user.groups" :value="m.id">{{ m.name }}</option>
                                </select>
                            </div>
                        </div>

                        <div class="alert alert-warning mt-3" role="alert" v-if="parseFloat(user.wallet.current_balance) < parseFloat(posFee)">
                            <p><strong>
                                Insufficient funds in your wallet.
                                Ensure you have enough funds in your wallet before proceeding.
                            </strong></p>
                        </div>

                        <div class="col-12 mt-3">
                            <button class="btn btn-success" style="width: 100%"
                                    :disabled="parseFloat(user.wallet.current_balance) < parseFloat(posFee)"
                                    data-bs-toggle="modal"
                                    @click="sendOtp"
                                    data-bs-target="#otpModal">
                                Pay {{ currencyFormat(posFee) }} for POS
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div
        class="modal fade"
        id="otpModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="otpModal"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-body">
                    <button style="float: right" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    <div class="col-12" style="padding: 10px">
                        <h4 class="text-success" style="font-weight: 600">Confirm Payment with OTP</h4>

                        <div class="mt-3">
                            <send-o-t-p-for-payment
                                :errors="errors"
                                @updatedotp="args => otp = args"
                            ></send-o-t-p-for-payment>
                        </div>

                        <div class="mt-2 mb-3">
                            <ResendOTP v-if="sentOtpToUser" :send-otp="sendOtp" :ref="setChildRef"/>
                        </div>

                        <button class="btn btn-success" style="width: 100%" @click.prevent="makePayment">
                            Pay
                            <i class="fa fa-spin fa-spinner" v-if="saving"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
.page-link {
    color: #00ab4d;
}
.page-link:hover {
    color: #00ab4d;
}
.active>.page-link, .page-link.active {
    z-index: 3;
    color: var(--bs-pagination-active-color);
    background-color: #00ab4d;
    border-color: #00ab4d;
}

.tooltip-1 {
    position: relative;
    display: inline-block;
}

.tooltip-1 .tooltip-text {
    visibility: hidden;
    width: 350px;
    background-color: #cff8dd;
    color: #00ab4d;
    //text-align: center;
    border-radius: 3px;
    padding: 10px;
    font-size: 10px;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    top: 25%;
    left: 50%;
    margin-left: 10px;
}

.tooltip-1:hover .tooltip-text {
    visibility: visible;
}
</style>
