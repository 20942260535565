<script setup>

import {onMounted, ref, watch} from "vue";
const currency = import.meta.env.VITE_LOCALIZATION_COUNTRY_CURRENCY_SIGN;
const errors = ref('');
const otp = ref('');
const props = defineProps([
  'product',
  'amount',
  'quantity',
  'borrowinglimit_clear',
  'balance_clear',
]);

function currencyFormat(amount) {
  let amounts = new Intl.NumberFormat('en-KE', {maximumSignificantDigits: 8}).format(amount)
  return `${currency}${amounts}`
}
</script>

<template>
  <div class="row">
    <div class="col-12 text-black">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Payment request</h4>
          <h6 class="card-subtitle text-muted">
            Confirm your request of {{currencyFormat(amount)}}.
          </h6>
        </div>
        <div class="card-body">
          <table class="table table-sm table-bordered">
            <tbody>
            <tr>
              <th scope="row">
                Product
              </th>
              <td>{{product?.product?.name}}</td>
            </tr>
            <tr>
              <th scope="row">
                Amount Requested
              </th>
              <td>
                {{currencyFormat(amount)}}
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

  </div>
</template>

<style scoped>

</style>
