<script setup>
import {computed, ref, watch} from "vue";
import {useOrderStore} from "../../../../../../store/Admin/order";
import {useAppStore} from "../../../../../../store/app";
import Swal from "sweetalert2";
import {useAuthStore} from "../../../../../../store/auth";

const orderStore = useOrderStore();
const appStore = useAppStore();
const userStore = useAuthStore();
const emits = defineEmits(['updateStep']);

const errors = ref({});
// const buyer_phone = ref('0791489172');
const buyer_phone = ref('');
const noSearchResults = ref(false);
const searchingForBuyer = ref(false);
const amount = ref(orderStore.selectedProduct.value.amount ?? 0);
const quantity = ref(1);
const daystopay = ref(1);
const initializeSearch = ref(false);
const saving = ref(false);
const bnplEnabled = ref(false);
const paymentmethod = ref(null);
const charges = ref({});
const validNumberOfDays = ref(false);
const buyerGroup = ref({});

const searchForBuyer = () => {
    searchingForBuyer.value = true;
    initializeSearch.value = true;
    errors.value = {};

    if(!buyer_phone.value){
        errors.value = {buyer_phone: 'Buyer phone is required'};
        searchingForBuyer.value = false;
        initializeSearch.value = false;
        return;
    }

    if(buyer_phone.value === orderStore?.seller?.value?.phone){
        errors.value = {buyer_phone: 'Buyer and seller cannot be the same'};
        searchingForBuyer.value = false;
        initializeSearch.value = false;
        return;
    }

    axios.post('/ajx/search_products', {
        search: buyer_phone.value
    }).then(resp => {
        if (resp.data.user == null) {
            noSearchResults.value = true;
            return;
        }

        noSearchResults.value = false;
        orderStore.buyer.value = resp.data.user;
        buyerGroup.value = resp?.data?.user?.my_group[0];

        axios.get(`/userFees?method=wallet&market_id=${buyerGroup.value?.id ?? ''}`).then((resp) => {
            charges.value = resp.data;
            validNumberOfDays.value = daystopay.value <= charges?.value.bnpl?.daily_fee_max_days ?? 365;
        });

    }).catch(() => {
        Swal.fire({
            title: "There was an error creating your order.",
            text: "We could not create an order for you. Contact the tech team.",
            icon: "error",
            confirmButtonText: 'Understood'
        });
    }).finally(() => {
        searchingForBuyer.value = false;
    })
};

const makeOrder = () => {
    saving.value = true;
    axios.post('/api/paywithwallet/makeorder', {
        product: orderStore.selectedProduct.value,
        quantity: quantity.value,
        amount: amount.value,
        seller: orderStore.seller.value,
        user_id: orderStore.buyer.value.id,
        auth_id: userStore.user.id,
        deduct_user_id: orderStore?.buyer?.value?.id ?? null,
        buyer_group_id: buyerGroup.value?.id,
        paymentMethod: paymentmethod.value,
        number_of_days: daystopay.value,
        total: totalAmount.value,
        agent_id: null,
        agent_fee: null,
        platform: 'call-to-transact',
    }).catch(() => {
        Swal.fire({
            title: "There was an error creating your order.",
            text: "We could not create an order for you. Contact the tech team.",
            icon: "error",
            confirmButtonText: 'Understood'
        });
    }).then(resp => {
        saving.value = false;
        emits('updateStep', 'orderconfirmed');
        orderStore.orderConfirmed.value = resp?.data;
        orderStore.orderConfirmed.value.total = totalAmount.value;
    });
};

const enableBNPL = async () => {
    bnplEnabled.value = true;
    paymentmethod.value = 'credit';

    await axios.get(`/userFees?market_id=${buyerGroup.value?.id ?? ''}`).then((resp) => {
        charges.value = resp.data;
        validNumberOfDays.value = daystopay.value <= charges?.value.bnpl?.daily_fee_max_days ?? 365;
    });
};

const cannotUseBNPL = computed(() => {
    if (parseInt(amount.value) > parseInt(orderStore.buyer?.value?.agent_capital?.balance ?? 0)) {
        return true;
    }
    bnplEnabled.value = false;
    paymentmethod.value = null;
});

const cannotMakePurchase = computed(() => {
    if (paymentmethod.value === 'credit') {
        return parseInt(amount.value) > parseInt(orderStore.buyer?.value?.agent_capital?.balance ?? 0);
    }else{
        return parseInt(amount.value) > parseInt(orderStore.buyer?.value?.wallet?.current_balance);
    }
});

const invalidDaysToPay = computed(() => {
    return paymentmethod.value === 'credit' && (!daystopay.value || daystopay.value < 0 || !validNumberOfDays.value);
});

const totalAmount = computed(() => {
    let fee = 0;
    let days = daystopay.value ? daystopay.value : 0;

    if (paymentmethod.value === "credit") {
        let bnplFee = 0;
        let bnplDailyFee = 0;

        if (charges.value) {
            if (charges.value.bnpl) {
                bnplFee = charges.value.bnpl.fee;
                bnplDailyFee = charges.value.bnpl.daily_fee;
            }
        }

        fee = (bnplFee + days * bnplDailyFee) * amount.value;
    }else{
        let marketFee = 0;

        if (charges.value) {
            if (charges.value.marketFee) {
                marketFee = charges.value.marketFee.fee;
            }
        }

        fee = marketFee * amount.value;
    }

    let totalCharges = amount.value * charges.value.charges_total;
    let totalCommissions = amount.value * getCommissions();

    return parseFloat(fee + totalCharges + amount.value + totalCommissions).toFixed(2);
});

watch(daystopay, function (newValue, oldValue) {
    validNumberOfDays.value = false;
    let maxDays = charges?.value.bnpl?.daily_fee_max_days ?? 365;

    if (newValue > maxDays) {
        errors.value = {daystopay: `The maximum number of days you can take to pay is ${maxDays}.`,};
    } else if (!daystopay.value || daystopay.value < 1) {
        errors.value = {daystopay: `Please input a valid number of days`,};
    } else {
        validNumberOfDays.value = true;
        errors.value = {};
    }
});

function getCommissions(){
    let total = 0;

    if(charges.value?.order_commissions){
        charges.value.order_commissions.forEach(commission => {
            if(commission.name === 'agent_order_commission'){
                return;
            }

            total += commission.value;
        });
    }

    return total;
}

</script>

<template>
    <div class="row">
        <div class="col-12">
            <div class="row">
                <div class="col-8">
                    <!--Label: Search for Buyer, Attributes:buyer_phone -->
                    <div class="form-floating">
                        <input type="text" class="form-control" id="buyer_phone" v-model="buyer_phone"
                               aria-describedby="buyer_phone-help"
                               :class="[errors.buyer_phone ? 'is-invalid': '',!errors.buyer_phone && Object.keys(errors).length > 1 ? 'is-valid': '']"
                        >
                        <label for="buyer_phone" class="form-label">Search for Buyer</label>

                        <div class="invalid-feedback" v-if="errors.buyer_phone">
                            {{ errors.buyer_phone.toString() }}
                        </div>
                    </div>
                </div>
                <div class="col-4 d-flex align-items-end align-self-center">
                    <button class="btn btn-outline-primary" @click="searchForBuyer">
                        Search
                        <i class="fa fa-spin fa-spinner" v-if="searchingForBuyer"></i>
                        <i class="fa fa-search" v-else></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-3" v-if="initializeSearch">
        <div v-if="noSearchResults">
            <div class="alert alert-danger" role="alert">
                <strong>No Buyer with that phone number has been found.</strong>
            </div>
        </div>
        <div v-else>
            <div class="card ">
                <div class="card-body ">
                    <div class="table-responsive">
                        <table class="table">
                            <tbody>
                            <tr>
                                <th>Name:</th>
                                <td>
                                    {{ orderStore.buyer?.value?.name }}
                                </td>
                            </tr>
                            <tr>
                                <th>Phone:</th>
                                <td>
                                    {{ orderStore.buyer?.value?.phone }}
                                </td>
                            </tr>
                            <tr>
                                <th>Email:</th>
                                <td>
                                    {{ orderStore.buyer?.value?.email }}
                                </td>
                            </tr>
                            <tr>
                                <th>Wallet Balance:</th>
                                <td>
                                    {{ orderStore.buyer?.value?.wallet?.currency }}
                                    {{ orderStore.buyer?.value?.wallet?.current_balance }}
                                </td>
                            </tr>
                            <tr>
                                <th>BNPL:</th>
                                <td>
                                    <div class="d-flex justify-content-between gap-2">
                                        <div>
                                            {{ appStore.currencySign }}
                                            {{ orderStore.buyer?.value?.agent_capital?.balance }}
                                        </div>
                                        <div>
                                            <template v-if="cannotUseBNPL">
                                                <button class="btn btn-outline-secondary btn-sm" disabled>
                                                    Use BNPL
                                                    <i class="fa-regular fa-eye-slash" v-if="cannotUseBNPL"></i>
                                                </button>
                                            </template>
                                            <template v-else>
                                                <button class="btn btn-outline-secondary btn-sm" @click="enableBNPL">
                                                    Use BNPL
                                                    <i class="fa fa-save fa-sm"></i>
                                                </button>
                                            </template>

                                        </div>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <form>
                            <fieldset>
                                <div class="row gap-3">
                                    <div class="col-12">
                                        <!--Label: Total Amount, Attributes:paymentTotal -->
                                        <div class="form-floating">
                                            <input type="text" class="form-control" disabled v-model="totalAmount">
                                            <label for="amount" class="form-label">Total Amount (charges incl.)</label>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <!--Label: Amount, Attributes:amount -->
                                        <div class="form-floating">
                                            <input type="number" class="form-control" id="amount" v-model="amount"
                                                   aria-describedby="amount-help"
                                                   :class="[errors.amount ? 'is-invalid': '',!errors.amount && Object.keys(errors).length > 1 ? 'is-valid': '']"
                                            >
                                            <label for="amount" class="form-label">Amount</label>

                                            <div class="invalid-feedback" v-if="errors.amount">
                                                {{ errors.amount.toString() }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <!--Label: Quantity, Attributes:quantity -->
                                        <div class="form-floating">
                                            <input type="number" class="form-control" id="quantity" v-model="quantity"
                                                   aria-describedby="quantity-help"
                                                   :class="[errors.quantity ? 'is-invalid': '',!errors.quantity && Object.keys(errors).length > 1 ? 'is-valid': '']"
                                            >
                                            <label for="quantity" class="form-label">Quantity</label>

                                            <div class="invalid-feedback" v-if="errors.quantity">
                                                {{ errors.quantity.toString() }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12" v-if="bnplEnabled">
                                        <!--Label: Days to Repay, Attributes:daystopay -->
                                        <div class="form-floating">
                                            <input type="number" class="form-control" id="daystopay" v-model="daystopay"
                                                   aria-describedby="daystopay-help"
                                                   :class="[errors.daystopay ? 'is-invalid': '',!errors.daystopay && Object.keys(errors).length > 1 ? 'is-valid': '']"
                                            >
                                            <label for="daystopay" class="form-label">Days to Repay</label>
                                            <div class="invalid-feedback" v-if="errors.daystopay">
                                                {{ errors.daystopay.toString() }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                        </form>
                    </div>
                    <div class="row tw-mt-5">
                        <div class="col-12 d-flex justify-content-end">
                            <div class="d-flex">
                                <div class="tw-ml-3">
                                    <template v-if="cannotMakePurchase">
                                        <div class="alert alert-danger" role="alert">
                                            <strong>You cannot make this purchase. Check the amount</strong>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <button
                                            class="btn btn-primary btn-sm"
                                            @click="makeOrder"
                                            :disabled="invalidDaysToPay"
                                        >
                                            <template v-if="paymentmethod === 'credit'">
                                                Create <strong>BNPL</strong> Order
                                            </template>
                                            <template v-else>
                                                Create <strong>Pay Now</strong> Order
                                            </template>
                                            <i class="fa fa-spin fa-spinner" v-if="saving"></i>
                                            <i class="fa fa-plus-circle fa-duotone" v-else></i>
                                        </button>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<style scoped>

</style>
