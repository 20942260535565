<script setup>

import {onMounted, ref} from "vue";
import moment from "moment/moment";
import {Bootstrap5Pagination} from "laravel-vue-pagination";

const currency = import.meta.env.VITE_LOCALIZATION_COUNTRY_CURRENCY_SIGN;
const props = defineProps(['charge', 'current_user_id']);
const chargePayments = ref({});

function formatDate(value) {
    return moment(value).format('DD/MM/YYYY HH:mm:ss');
}

const getChargePayments = async (page = 1) => {
    await axios.get(`/four/market/charges/${props.charge.id}/payments?page=${page}`).then(resp => {
        chargePayments.value = resp.data.data;
    }).catch(err => {
        errors.value = err.response.data.errors;
    });
}

onMounted(() => {
    getChargePayments();
})

</script>

<template>
    <div>
        <div class="row row-cols-1 row-cols-md-2 row-cols-xl-4">
            <div class="col">
                <div class="transaction-history-summary-card radius-10" style="border-radius: 0.25rem;border: 0.2px solid rgb(29, 36, 46);">
                    <div class="card-body">
                        <div class="d-flex align-items-center">
                            <div>
                                <p class="mb-0 text-secondary">Charge Type</p>
                                <h4 class="my-1">{{ charge.type }}</h4>
                            </div>

                        </div>
                        <a class="text-secondary"> {{ charge.market?.name }}</a>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="transaction-history-summary-card radius-10" style="border-radius: 0.25rem;border: 0.2px solid rgb(29, 36, 46);">
                    <div class="card-body">
                        <div class="d-flex align-items-center">
                            <div>
                                <p class="mb-0 text-secondary">Percentage</p>
                                <h4 class="my-1">{{ charge.percentage }}%</h4>
                            </div>

                        </div>
                        <a class="text-secondary" >Amount charged to members</a>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="transaction-history-summary-card radius-10" style="border-radius: 0.25rem;border: 0.2px solid rgb(29, 36, 46);">
                    <div class="card-body">
                        <div class="d-flex align-items-center">
                            <div>
                                <p class="mb-0 text-secondary">Amount in Wallet</p>
                                <h4 class="my-1">{{ currency }}{{ charge.total_balance.toLocaleString() }}</h4>
                            </div>

                        </div>
                        <a class="text-secondary">Total amount in wallet</a>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="transaction-history-summary-card radius-10" style="border-radius: 0.25rem;border: 0.2px solid rgb(29, 36, 46);">
                    <div class="card-body">
                        <div class="d-flex align-items-center">
                            <div>
                                <p class="mb-0 text-secondary">Cashout Bank</p>
                                <h4 class="my-1" style="font-size: 14px; color: black">
                                    <strong>
                                        {{ charge.bank?.name ?? 'N/A' }} <br/>
                                        {{ charge.cashout_account_number }} <br/>
                                        {{ charge.cashout_account_name }}
                                    </strong>
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-4" >
            <div class="col-12">
                <button
                    class="btn btn-success"
                    id="cashoutWalletBtn"
                    data-bs-toggle="modal"
                    data-bs-target="#cashoutWallet"
                    v-if="current_user_id == charge.user_id"
                >
                    <i class="fa-light fa-sm fa-money-simple-from-bracket"></i>
                    Cashout Wallet
                </button>
                <div class="alert alert-warning" role="alert" v-if="current_user_id != charge.user_id"
                >
                    <strong>Only {{charge.authorized_cashout_user?.name}} can cashout from this charge</strong>
                </div>
                &nbsp;
                <delete-charge-fee
                    :deleteType="'charge'"
                    :deleteUrl="`/four/market/charges-rent-fees-and-taxes/${charge.id}`"
                    v-if="current_user_id === charge.creator_id"
                >
                </delete-charge-fee>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table">
                                <thead v-if="chargePayments && chargePayments.data && chargePayments.data.length > 0">
                                <tr>
                                    <th>#</th>
                                    <th>Market Member</th>
                                    <th>Order</th>
                                    <th>Amount</th>
                                    <th>Type</th>
                                    <th>Balance</th>
                                    <th>Previous Balance</th>
                                    <th>Date</th>
                                </tr>
                                </thead>
                                <tbody v-if="chargePayments && chargePayments.data && chargePayments.data.length > 0">
                                <tr v-for="(chargePayment,index) in chargePayments.data" :key="chargePayment.id">
                                    <td scope="row">{{ index + 1 }}</td>
                                    <td>{{ chargePayment.user.name }}</td>
                                    <td>{{ chargePayment.meta?.order_number ?? '-' }}</td>
                                    <td><span class="badge bg-dark">{{ currency }}{{ chargePayment.amount.toLocaleString() }}</span></td>
                                    <td>
                                        <span v-if="chargePayment.type === 'credit'" class="badge bg-success">
                                            {{ chargePayment.type }}
                                        </span>
                                        <span v-else class="badge bg-danger">
                                            {{ chargePayment.type }}
                                        </span>
                                    </td>
                                    <td>{{ currency }}{{ chargePayment.new_balance.toLocaleString() }}</td>
                                    <td>{{ currency }}{{ chargePayment.old_balance.toLocaleString() }}</td>
                                    <td>{{ formatDate(chargePayment.created_at) }}</td>
                                </tr>
                                </tbody>
                                <tbody v-else>
                                <tr>
                                    <td align="center" colspan="7">No record found.</td>
                                </tr>
                                </tbody>
                            </table>
                            <bootstrap5-pagination :data="chargePayments" @pagination-change-page="getChargePayments" align="center"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div
        class="modal fade"
        id="cashoutWallet"
        tabindex="-1"
        role="dialog"
        aria-labelledby="cashoutWallet"
        aria-hidden="true"
    >
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-body">
                    <button type="button" class="btn-close" style="float: right" data-bs-dismiss="modal" aria-label="Close"/>
                    <div class="col-12 mt-3" style="padding: 10px">
                        <h4 class="text-success">Input OTP to cashout wallet</h4>
                        <p class="mt-2">
                            {{ charge.bank?.name ?? 'N/A' }} <br/>
                            {{ charge.cashout_account_number }} <br/>
                            {{ charge.cashout_account_name }}
                        </p>
                        <fee-charge-cashout
                            :type="'charge'"
                            :type_id="charge.id"
                            :market="charge.market"
                            :typeObject="charge"
                            :total_amount="charge.total_balance"
                            :current_user_id="current_user_id"
                        ></fee-charge-cashout>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
