<script setup>

import {onMounted, ref} from "vue";
import {Bootstrap5Pagination} from 'laravel-vue-pagination';
import moment from "moment/moment";

const currency = import.meta.env.VITE_LOCALIZATION_COUNTRY_CURRENCY_SIGN;
const transactions = ref({});
const errors = ref({});
const searchTransaction = ref('');
const selectedTransaction = ref({});

function populateBankTransaction(bankTransaction) {
    if (bankTransaction) {
        selectedTransaction.value = bankTransaction;
    }
}

const getPartnerBankTransactions = async (page = 1) => {
    await axios.get(`/apis/v4/saas/pb-transactions?page=${page}&keyword=${searchTransaction.value}`).then(resp => {
        transactions.value = resp.data.data;
    }).catch(err => {
        errors.value = err.response.data.errors;
    });
}

function setSearchTransaction() {
    let urlParams = new URLSearchParams(window.location.search);
    searchTransaction.value = urlParams.has('search') ? urlParams.get('search') : '';
}

function formatDate(value) {
    return moment(value).format('DD/MM/YYYY HH:mm:ss');
}

onMounted(function () {
    setSearchTransaction();
    getPartnerBankTransactions();
});

</script>

<template>
    <div>
        <div class="row">
            <div class="card">
                <div class="card-body">
                    <div class="col-12">
                        <form action="/four/admin/bank-transactions" method="GET">
                            <div class="col-md-6">
                                <div class="input-group mb-3">
                                    <input type="text" class="form-control" placeholder="Search by account, account number or bank"
                                           name="search"
                                           id="searchTransaction"
                                           :value="searchTransaction"
                                           aria-label="Search Transaction" aria-describedby="button-addon2">
                                    <button class="btn btn-outline-secondary" type="submit" id="performSearchTransaction">
                                        Search
                                        <i class="fa fa-search"></i>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="table-responsive">
                        <table class="table">
                            <thead v-if="transactions && transactions.data && transactions.data.length > 0">
                            <tr>
                                <th>#</th>
                                <th>Sender</th>
                                <th>Sender No.</th>
                                <th>Amount</th>
                                <th>Status</th>
                                <th>Receiver</th>
                                <th>Receiver No.</th>
                                <th>Receiver Bank</th>
                            </tr>
                            </thead>
                            <tbody v-if="transactions && transactions.data && transactions.data.length > 0">
                            <tr v-for="(transaction,index) in transactions.data" :key="transaction.id">
                                <td scope="row">{{ index + 1 }}</td>
                                <td>{{ transaction.sender_account_name }}</td>
                                <td>{{ transaction.sender_account_number }}</td>
                                <td>
                                    <span class="badge bg-dark">
                                        {{ currency }}{{ transaction.amount.toLocaleString() }}
                                    </span>
                                </td>
                                <td v-if="transaction.status === 'success'">
                                    <span class="badge bg-success">{{ transaction.status }}</span>
                                </td>
                                <td v-else-if="transaction.status === 'pending'">
                                    <span class="badge bg-warning text-dark">{{ transaction.status }}</span>
                                </td>
                                <td v-else>
                                    <span class="badge bg-danger">{{ transaction.status }}</span>
                                </td>
                                <td>{{ transaction.account_name }}</td>
                                <td>{{ transaction.account_number }}</td>
                                <td>{{ transaction.bank }}</td>
                                <td>
                                    <button class="btn btn-sm btn-default text-primary"
                                            @click="populateBankTransaction(transaction)"
                                            data-bs-toggle="offcanvas" href="#offcanvasExample" role="button"
                                            aria-controls="offcanvasExample"
                                    >
                                        See More <i class="fa fa-angle-double-right fa-thin"></i>
                                    </button>
                                </td>
                            </tr>
                            </tbody>
                            <tbody v-else>
                            <tr>
                                <td align="center" colspan="7">No record found.</td>
                            </tr>
                            </tbody>
                        </table>
                        <div class="col-12">
                            <bootstrap5-pagination :data="transactions" @pagination-change-page="getPartnerBankTransactions" style="overflow: scroll"/>
                        </div>
                    </div>
                    <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasExample"
                         aria-labelledby="offcanvasExampleLabel">
                        <div class="offcanvas-header">
                            <h5 class="offcanvas-title" id="offcanvasExampleLabel">Bank Transaction</h5>
                            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div>
                        <div class="offcanvas-body">
                            <div class="table-responsive">
                                <table class="table table-borderless">
                                    <tbody>
                                    <tr>
                                        <td>Transaction Type:</td>
                                        <td><b>{{ selectedTransaction?.type }}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Sender Account Name:</td>
                                        <td><b>{{ selectedTransaction?.sender_account_name }}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Sender Account Number:</td>
                                        <td><b>{{ selectedTransaction?.sender_account_number }}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Sender Bank:</td>
                                        <td><b>{{ selectedTransaction?.sender_bank }}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Amount:</td>
                                        <td>
                                            <span class="badge bg-dark">
                                                  {{ currency }}{{ selectedTransaction?.amount?.toLocaleString() }}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Bank Charge:</td>
                                        <td class="text-success">
                                            <span class="badge bg-dark">
                                                {{ currency }}{{ selectedTransaction?.bank_charge?.toLocaleString() }}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Receiver Account Name:</td>
                                        <td><b>{{ selectedTransaction?.account_name }}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Receiver Account Number:</td>
                                        <td><b>{{ selectedTransaction?.account_number }}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Receiver Bank:</td>
                                        <td><b>{{ selectedTransaction?.bank }}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Sent Date:</td>
                                        <td><b>{{ formatDate(selectedTransaction?.created_at) }}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Sender Remark:</td>
                                        <td><b>{{ selectedTransaction?.sender_remark }}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Status:</td>
                                        <td v-if="selectedTransaction?.status === 'success'">
                                            <span class="badge bg-success">{{ selectedTransaction?.status }}</span>
                                        </td>
                                        <td v-else-if="selectedTransaction?.status === 'pending'">
                                            <span class="badge bg-warning text-dark">{{ selectedTransaction?.status }}</span>
                                        </td>
                                        <td v-else>
                                            <span class="badge bg-danger">{{ selectedTransaction?.status }}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Reference:</td>
                                        <td><b>{{ selectedTransaction?.reference }}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Transact ID:</td>
                                        <td><b>{{ selectedTransaction?.transact_id ?? '---' }}</b></td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
.page-link {
    color: #00ab4d;
}
.page-link:hover {
    color: #00ab4d;
}
.active>.page-link, .page-link.active {
    z-index: 3;
    color: var(--bs-pagination-active-color);
    background-color: #00ab4d;
    border-color: #00ab4d;
}
</style>
