<script setup>
import Stepper from 'bs-stepper'
import {onMounted, ref} from "vue";
import SendOTPForPayment from "../payments/sendOTPForPayment.vue";
import InlineTopUp from "../payments/inlinetopup/InlineTopUp.vue";
import Swal from "sweetalert2";

const currency = import.meta.env.VITE_LOCALIZATION_COUNTRY_CURRENCY_SIGN;
let stepper;
const errors = ref([]);
const otp = ref('');
const saving = ref(false);
const currentIndex = ref(0);
const keyinlinetopup = ref(0);
const topup = ref(false);
const sentOtpToUser = ref(false);

const toppedUpAmount = ref(0);

const props = defineProps([
    'user_id',
    'phone',
    'balance',
    'order_amount',
    'order_id',
    'order_type',
    'virtual_bank_account'
]);

onMounted(function () {
    document.addEventListener('DOMContentLoaded', function () {
        stepper = new Stepper(document.querySelector('.bs-stepper'))
    });

});

function currencyFormat(amount) {
    let amounts = new Intl.NumberFormat("en-KE", {
        maximumSignificantDigits: 8,
    }).format(amount);
    return `${currency}${amounts}`;
}

function updateTopUpAMount() {
    topup.value = true
}

defineExpose({
    walletToppedUp,
    sendOTP,
});

function walletToppedUp() {
    completePayments(toppedUpAmount.value);
}

function sendOTP() {
    sentOtpToUser.value = true;
    let payAmount = currencyFormat(parseFloat(props.order_amount).toFixed(2));

    axios.post('/apis/v4/otp/send', {
        'phone': props.phone,
        'user_id': props.user_id,
        'message': `You are about to pay ${payAmount} from your wallet. ${payAmount} will be removed from your wallet. To accept, enter this code {otp}`,
        // 'message': 'Your one-time password to complete payment on Amana Market is {otp} and is valid for the next 2 minutes.\n',
        'key': 'pay_for_order',
    }).then(function () {
        sentOtpToUser.value = true;
    }).catch(function () {
        sentOtpToUser.value = false;
    });
}

function nextPage() {
    axios.post('/apis/v4/otp/validate', {
        otp: otp.value,
        user_id: props.user_id,
        key: 'pay_for_order',
    }).then(() => {
        currentIndex.value++;
        stepper.next();
    }).catch((error) => {
        errors.value = error?.response?.data?.errors ?? '';
    });
}

function previousPage() {
    location.reload();
}

function completePayments(amount) {
    saving.value = true;

    let amountToPay = parseInt(props.order_amount) - parseInt(props.balance);
    amountToPay = amountToPay > 0 ? amountToPay : 0;

    axios.post('/apis/v4/completePayment', {
        'user_id': props.user_id,
        'order_id': props.order_id,
        'type': props.order_type,
        'amount': amount,
    }).then(() => {
        saving.value = false;
        let myModalEl = document.querySelector('#payfororder')
        let modal = bootstrap.Modal.getOrCreateInstance(myModalEl);
        modal.hide();

        if(amountToPay > 0 && amountToPay > amount){
            location.href = '/four';
        }else{
            location.reload();
        }

    }).catch(() => {
        saving.value = false;

        Swal.fire({
            position: 'top',
            icon: 'error',
            title: 'Error completing payment. Please try again and contact our support team if problem persists.',
            timer: 9000
        }).then(() => {
            location.reload();
        });
    });
}
</script>

<template>
    <button class="btn btn-secondary btn-sm radius-30 px-4" data-bs-toggle="modal"
            @click="sendOTP"
            data-bs-target="#payfororder">
        Pay
    </button>
    <div class="modal fade" id="payfororder" tabindex="-1" role="dialog"
         aria-labelledby="PayforOrder"
         aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title" id="PayforOrder">Pay for Order </h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal"
                            aria-label="Close">
                    </button>
                </div>
                <div class="modal-body">
                    <div class="bs-stepper">
                        <div class="bs-stepper-header" role="tablist">
                            <!-- your steps here -->
                            <div class="step" data-target="#logins-part">
                                <button type="button" class="step-trigger" role="tab" aria-controls="logins-part"
                                        id="logins-part-trigger">
                                    <span class="bs-stepper-circle">1</span>
                                    <span class="bs-stepper-label">OTP</span>
                                </button>
                            </div>
                            <div class="line"></div>
                            <div class="step" data-target="#information-part">
                                <button type="button" class="step-trigger" role="tab" aria-controls="information-part"
                                        id="information-part-trigger">
                                    <span class="bs-stepper-circle">2</span>
                                    <span class="bs-stepper-label">Make Payment</span>
                                </button>
                            </div>
                        </div>
                        <div class="bs-stepper-content">
                            <div id="logins-part" class="content" role="tabpanel" aria-labelledby="logins-part-trigger">
                                <send-o-t-p-for-payment
                                    v-if="sentOtpToUser"
                                    :errors="errors"
                                    @updatedotp="args => otp = args"
                                ></send-o-t-p-for-payment>
                                <div v-else class="col-12 mt-3">
                                    <div class="alert alert-danger" role="alert">
                                        <div class="d-flex justify-content-between">
                                            <div>
                                                <i class="fa fa-info-circle fa-3x"></i>
                                            </div>
                                            <div class="px-4 flex-grow-1">
                                                <p>
                                                    There was an error sending OTP to the
                                                    <strong>phone number</strong> associated with your
                                                    account. Please check your details and try again.
                                                    If the problem persists, please contact our support team.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="information-part" class="content" role="tabpanel"
                                 aria-labelledby="information-part-trigger">
                                <div class="alert alert-success" role="alert"
                                     v-if="parseFloat(order_amount) <= parseFloat(balance)">
                                    <strong>
                                        You have <strong>{{ parseFloat(balance).toLocaleString() }}</strong> in your
                                        wallet. It is
                                        sufficient to pay for this
                                        order
                                    </strong>
                                </div>
                                <div class="alert alert-warning" role="alert" v-else>
                                    <strong>
                                        You do not have enough funds in your wallet. Click on next and select an option to top your wallet.
                                    </strong>
                                </div>

                                <div class="d-flex justify-content-center" v-if="topup">
                                    <div class="col-6 border border-3 p-4">
                                        <InlineTopUp
                                            ref="inlineTopUp"
                                            :key="keyinlinetopup"
                                            @walletToppedUp="walletToppedUp"
                                            :amountToPay="parseInt(order_amount) - parseInt(balance)"
                                            :virtual_bank_account="props.virtual_bank_account"
                                            @walletToppedUpAmount="args => toppedUpAmount = args"
                                        ></InlineTopUp>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <template v-if="currentIndex === 0">
                        <button v-if="sentOtpToUser" type="button" class="btn btn-outline-secondary" @click="nextPage">Next</button>
                        <button v-else type="button" class="btn btn-outline-secondary" @click="previousPage">Back</button>
                    </template>
                    <template v-else>
                        <div v-if="parseFloat(order_amount) <= parseFloat(balance)">
                            <button type="button" class="btn btn-outline-secondary"
                                    :disabled="saving"
                                    @click="completePayments(order_amount)">
                                Complete
                                <i class="fa fa-save" v-if="!saving"></i>
                                <i class="fa fa-spin fa-spinner" v-else></i>
                            </button>
                        </div>
                        <div v-else>
                            <button type="button" class="btn btn-secondary px-4"
                                    @click="updateTopUpAMount"
                                    id="topupaccount"
                            >
                                Next
                            </button>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
